@import '../css/oh-styles-1.3.0';
@import './overrides';
@import './custom-styles';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/dropdown';

.btn:focus-visible {
  outline: unset;
  outline: -webkit-focus-ring-color auto 1px !important;
}

.info-span {
  font-size: 1.125rem;
  color: $card-icon-text-color;
}
