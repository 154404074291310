$white: #ffffff !default;
$blue: #1976d2 !default;
@import '../../../../../shared/client/src/scss/overrides';

/* You can add global styles to this file, and also import other style files */
$onec-landing-enabled: true;
$fieldset-enabled: true;
$toast-enabled: true;
$checkbox-enhanced-enabled: true;
$radio-enhanced-enabled: true;
$stepper-hdot-enabled: true;

$a11y-links-state-classes: true;
$a11y-links-fresh-state: true;
$a11y-links-disabled-state: true;

$divider-enabled: true;
$divider-h-enabled: true;
$divider-h-centered-enabled: true;
$divider-h-double-enabled: true;

$form-control-len-char: true;
$form-control-len-num: true;

$bootstrap-code-enabled: true;

$onec-branding-font-enabled: true;

$dropdown-link-hover-bg: $blue;
$dropdown-link-hover-color: $white;
$dropdown-link-active-color: $blue-900;
$dropdown-link-active-bg: $white;
$dropdown-padding-x: 0;
$dropdown-padding-y: 1rem;
$dropdown-spacer: 0.5rem;
$dropdown-item-padding-y: $spacer * 0.5;
$dropdown-item-padding-x: $spacer * 1.5;

$btn-active-box-shadow: inset 0;

$card-icon-text-color: #0d47a1;
