html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
}

body {
  padding: 0 !important;
}

// [TODO]: Review with REX Library
.btn {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  line-height: 1.5;
}

// [TODO]: Review with REX Library
.btn-link {
  padding: 15px;
}

// [TODO]: Review with REX Library
.btn-continue {
  background-color: #137bc1;
  color: white;

  &:hover {
    color: white;
    background-color: #137bc1;
  }
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: #eeeeee;
}

// [TODO]: Review with REX Library
.font-subtitle {
  color: #737373;
  font: normal normal normal 14px/20px Open Sans;
}

.modal-dialog-end {
  align-items: end;

  @media (min-width: 767px) {
    align-items: center;
  }
}

.text-btn-link-container {
  display: inline-flex;
  align-items: baseline;
}

.text-btn-link-container > .btn-link {
  padding: 0 5px 0 5px;
}

.tab-container {
  .nav-tabs {
    border-bottom: 0;
  }

  .sign-in-tab {
    text-align: center;
    margin: 35px;

    @media (min-width: 767px) {
      margin: 35px 0;
      text-align: left;
      justify-content: flex-start;
    }

    .nav-link {
      border: $btn-border-width solid $gray-700;
      color: $black;
      border-radius: 0;
      display: none;

      &.active {
        background-color: $primary;
        color: $white;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    &:first-child .nav-link {
      border-top-left-radius: $btn-border-radius;
      border-bottom-left-radius: $btn-border-radius;
    }

    &:last-child .nav-link {
      border-top-right-radius: $btn-border-radius;
      border-bottom-right-radius: $btn-border-radius;
    }

    .card-img-top {
      width: 100%;
    }

    &.tab-pane {
      margin-top: 1rem;

      .card {
        min-height: 6.75rem;
        margin: 0 auto;
        max-width: 260px;

        @media (min-width: 767px) {
          margin: 0;
        }
      }
    }
  }
}

.modal-content {
  box-shadow: 0 16px 24px #53535326;
  border: 1px solid #9ad5f8;
  border-radius: 4px;
}

.app-button-container {
  border-bottom: 1px solid #cfcfcf;

  button {
    border-radius: 0px;
  }
}

.apps-wrapper {
  display: flex;
  flex-direction: row;
  //gap: 20px;
  flex-wrap: wrap;
}

.card--dl-centered {
  text-align: center;
}

.card-dl-rows {
  display: flex;
  flex-direction: column;
}

// footer
.card-footer {
  background: #f4f7fc;

  .link {
    font-size: 14px;
  }

  gap: 8px;

  .card-footer--content {
    display: flex;
    flex-direction: row;
    text-align: right;
    justify-content: flex-end;
    gap: 8px;
  }
}

.card-body {
  display: flex;
  flex: 1;
  flex-direction: row;

  .card-body--content {
    flex-grow: 0;
    width: 100%;
    margin-top: 8px;

    dl {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0px;

      .center-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        width: 48px;
        height: 48px;
        margin-bottom: 8px;

        .text {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px;
        }
      }
    }

    .abstract-text,
    .brief {
      font: normal normal 600 14px/20px Open Sans;
      letter-spacing: 0.14px;
      margin-bottom: 0px;
      white-space: break-spaces;
    }

    .brief {
      font: normal normal normal 14px/20px Open Sans;
    }
  }

  .card-icon {
    cursor: pointer;
  }
}

// generic scss rules
.card.card--grid-cell {
  min-width: 17.5rem;
  height: 12.5rem;
}

.card.card--margins-absolute {
  .card-body {
    position: relative;
  }

  .card-body--left,
  .card-body--right {
    position: absolute !important;
  }
}

// card-margin
.card-margin--left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-margin--right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 14px;
}

.form-control {
  border: 1px solid $gray-600;
  border-radius: 4px;
}

.width-180 {
  min-width: 180px;
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }
}

// Overriding REX typography
// h1, h2, h3, h4, h5 and h6
h1,
.h1 {
  font-size: 1.75rem;
  letter-spacing: 0rem;
  line-height: 2.375rem;
  margin: 2.5rem 0 1.5rem 0;
}

h2,
.h2 {
  font-size: 1.25rem;
  letter-spacing: 0rem;
  line-height: 1.875rem;
}

h3,
.h3 {
  font-size: 1rem;
  letter-spacing: 0rem;
  line-height: 1.625rem;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-size: 1rem;
  letter-spacing: 0rem;
  line-height: 1.5rem;
}

.help-menu a,
a:visited {
  text-decoration: none;
  color: #1a1a1a;
}

.help-menu a:hover {
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.link-primary {
  cursor: pointer;
}

$link-primary-dark-blue: #0d47a1;

.link-primary-dark {
  @extend .link-primary;
  color: $link-primary-dark-blue !important;
}

.panel-body.card-block.card-body {
  max-height: 116px;
  overflow-y: auto;

  @include media-breakpoint-up(md) {
    max-height: 126px;
  }
}

.panel.card.panel-default {
  border: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color) !important;
  --#{$prefix}card-border-radius: unset !important;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #eeeeee;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c8c6c6;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #c3c2c2;
  }
}

.panel:first-child {
  .panel.card.panel-default:first-child {
    --#{$prefix}card-border-radius: 0.25rem 0.25rem 0 0 !important;
  }
}

.panel:last-child {
  .panel.card.panel-default:last-child {
    --#{$prefix}card-border-radius: 0 0 0 0 !important;
  }

  .panel-heading.card-header.panel-enabled {
    background: unset;
  }
}

.modal-footer.top-border-remove {
  border-top: unset;
}

.row typeahead-container {
  height: 210px !important;
}

.font-raleway {
  font-family: var(--font-family-raleway);
}

body,
.text-normal {
  line-height: 1.5;
}

.form-check {
  min-height: 2.25rem;
}

[type='radio'].form-check-input:checked {
  border-color: #1976d2;
}

div.content {
  padding-left: initial;
}

.fas {
  width: 1.625rem;
  height: 1.625rem;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;

  @include media-breakpoint-up(md) {
    width: 1.875rem;
    height: 1.875rem;
  }
}

.fa-nav-help {
  background-image: url('../assets/images/Help\ -\ header.svg');
}

.fa-nav-uao {
  background-image: url('../assets/images/UAO\ -\ header.svg');
}

.fa-profile-avatar {
  background-image: url('../assets/images/Profile\ avatar.svg');
}
