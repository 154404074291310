$gray-300: #eeeeee !default;
$gray-400: #cfcfcf !default;
$gray-600: #888888 !default;
$gray-700: #737373 !default;
$black: #1a1a1a !default;
$blue-50: #f2fbff !default;
$blue-900: #0d47a1 !default;
$purple: #6f42c1 !default;
$lavender-50: #f4f7fc !default;
$spacer: 1rem !default;
$font-family-sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-size-base: 1rem !default;
$line-height-base: 1.5 !default;
$sub-sup-font-size: 0.75em !default;
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

$primary-light: $blue-50;
$primary-dark: $blue-900;

$shared-document-container-background: $primary-light 0% 0% no-repeat padding-box !default;
$shared-document-container-padding: 0 0.25rem !default;
$shared-light-link-visited: $purple !default;
$gray: $gray-600;
